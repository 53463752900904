<template>
  <modal-card :title="step.label" :processing="processing">
    <div
      v-if="
        !$store.getters['auth/isCurrentUser'](userId) &&
          $store.getters['user/isAdmin']()
      "
      slot="subheader"
      class="has-background-warning has-padding-100 has-text-centered"
    >
      <p>
        <b-icon icon="shield-alt" size="is-small" />
        <span>Tread carefully – you're acting on behalf of the customer.</span>
      </p>
    </div>

    <loading v-if="loading" />

    <component
      :is="step.component"
      v-show="!loading"
      :user-id="userId"
      :cart="computedCart"
      :processing="processing"
      @cartdata="cart = $_.merge(cart, $event)"
    />

    <button
      slot="footer"
      :disabled="!step.isValid() || processing"
      :class="{ 'is-loading': processing }"
      type="submit"
      class="button is-success"
      @click="nextStep"
    >
      Continue
    </button>
  </modal-card>
</template>

<script>
export default {
  name: "RenewModal",
  props: {
    contractId: {
      type: String,
      required: false,
      default: ""
    },
    userId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      loading: true,
      processing: false,
      cart: {
        credit: null,
        paymentMethod: null,
        termsAgreed: false,
        contractId: this.contractId || null
      },
      stepNum: 0,
      steps: [
        {
          label: "Select payment method",
          component: () =>
            import("@shared/cart/_selectPaymentMethod").finally(() => {
              this.loading = false;
            }),
          isValid: () => {
            return this.$_.isObject(this.cart.paymentMethod);
          }
        },
        {
          label: "Confirm renewal",
          component: () =>
            import("@shared/cart/_confirmRenewal").finally(() => {
              this.loading = false;
            }),
          isValid: () => {
            return this.cart.termsAgreed;
          }
        }
      ]
    };
  },
  computed: {
    step() {
      return this.steps[this.stepNum];
    },
    computedCart() {
      return this.$_.merge({}, this.cart, {
        credit: this.$store.getters["billing/credit"](this.userId)
      });
    },
    user() {
      return this.$store.getters["user/user"](this.userId);
    }
  },
  created() {
    Promise.all([this.getCredit(), this.observeUser()])
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        this.$toast.open({
          message: `${error.message}`,
          type: "is-danger"
        });
      });
  },
  beforeDestroy() {
    this.$store.dispatch("billing/unobserveCredit", {
      userId: this.userId
    });
    this.$store.dispatch("user/unobserveUser", {
      userId: this.userId
    });
  },
  methods: {
    getCredit() {
      return this.$store.dispatch("billing/observeCredit", {
        userId: this.userId
      });
    },
    observeUser() {
      return this.$store.dispatch("user/observeUser", {
        userId: this.userId
      });
    },
    nextStep() {
      if (this.stepNum < this.steps.length - 1) {
        this.$_.debounce(() => {
          this.loading = true;
        }, 250);
        this.stepNum++;
      } else {
        this.doRenewal();
      }
    },
    doRenewal() {
      if (this.processing) return;
      this.processing = true;
      this.$store
        .dispatch("billing/renewContract", {
          paymentMethodId: this.cart.paymentMethod.id,
          contractId: this.contractId
        })
        .then(result => {
          this.$emit("close");
          this.$toast.open({
            message: `${result}`
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
